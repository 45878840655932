* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
}
.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}
.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  color: #fff;
  @media screen and (max-width: 768px){
    display:flex;
    flex-direction: column;
    text-align: left;
    width: 100%;

  }
}

.menu-items:nth-of-type(odd) {
  font-size: 15px;
  width: 100%;
}
.menu-items:nth-of-type(even) {
  font-size: 15px;
  width: 100%;
}
.menu-items-menu{
  font-size: 15px;
  width: 100%;
  background-color:#fff;
}

.mobilelot-image{
  display: flex;
  margin-top: -4.1%;
  width:100%;
  @media screen and (max-width: 768px){
    display:none;
  }
}
.mobile-lot-home-menu{
  margin-left: 5%;
  margin-right:5%;
  margin-top: 25px;
  @media screen and (max-width: 768px){
    margin: -5%;
  }
}
.footer_menu_bottom{
  @media screen and (max-width: 768px){
    margin-top: 65%;
  }
}
.mobilelot_logo{
  margin-left:20%;
  @media screen and (max-width: 768px){
    margin-left:2%;
    margin-top: -10%;
  }
}
.language_seleactor_menu{
  margin-top: 2%;
  text-align: right; 
  margin-right: 3%;
  @media screen and (max-width: 768px){
    justify-content: center;
    margin-bottom: -2%;
  }
}
.home_page_card_heading{
  margin-left: 5%;
  margin-right:5%;
  margin-top: 20px;
}
.tittle_box{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header_box{
  background-color: '#EBE2E2';
  border-radius: 5;
  color: "#5C5454";
  min-height: 150;
  padding: 15;
  width: 300;
  font-weight: bold;             
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header_box2{
  background-color: "#EBE2E2";
  border-radius: 5;
  color: "#5C5454";
  min-height: 150;
  width: 300;
  font-weight: bold;             
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 30%;
}
.menu_home{
  display:flex;
  flex-direction:row;
  justify-content: space-between,
}
.footer_menu{
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 100px;
  @media screen and (max-width: 768px){
    display:none;
  }
}

overall_menu{
  @media screen and (max-width: 768px){
    margin-left:5%;
    margin-right:5%;
  }
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a, .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #170e6b;
  color: #fff;
  padding: 5;
  @media screen and (max-width: 768px){
    color: #fff;
    padding: 8;
    background-color: #170e6b;
  }
}

.image_content{
  margin-left: 120%;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
  
}

.dropdown {
  position: absolute;
  left: 0;
  right: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 15rem;
  padding: 0.0rem;
  list-style: none;
  background-color: rgb(247, 239, 239);
  border-radius: 0.0rem;
  display: none;
  color: #000;
  @media screen and (max-width: 768px){
    left: 0;
    right: auto;
  }
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.video_content_header{
  /* background-color: rgb(247, 239, 239);
  display: flex;
  align-items: center;
  padding: 30px;
  width:100%;
  margin-left: 0%;
  margin-top: 0.3px; */
  @media screen and (max-width: 768px){
    display: flex;
    align-items: center;
    padding: 30px;
    width:100%;
    margin-left: 0%;
    margin-top: 0.7px;
    font-size: 18px;
  }
}
.video_content_frame{
  display: flex;
  align-items:center;
  justify-content: center;
  height: 100%;
  margin-top: 0px;
  width:100%;
}
.iframe_content{
  height: 42vw;
  width: 1140vw;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 768px){
    height: 50vw;
    width: 150vw;
    position: relative;
  }
}

/* content */

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
 
}

.content a {
  color: #cc3852;
  margin-right: 10px;
}